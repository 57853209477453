import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import espadilhaIcon from "./assets/espadilha-icon.svg";
import logo from "./assets/logo.svg";
import { useNavigate } from 'react-router-dom';
import "./style.css";
import Background from "./assets/background.svg";
import Wheel from "./assets/wheel.svg";
import RoulletIcon from "./assets/roulletIcon.svg";
import Marker from "./assets/pin.png";

const Headline = styled.h1`
    margin: 5px 0px 10px 0px;
    text-align: center;
    font-family: "Gotham-Medium";
    font-size: 25px;
    color: white;
`;

const CardComponent = styled.div`
    display: block;
    border: transparent;
`;

const ModalComponent = styled.div`
    background-color: #232426;
    padding: 10px 0px 10px 0px;
    border-radius: 8px;
`;

const Container = styled.div`
  background-image: url(${Background});
  text-align: center;
  padding: 15px;
  position: relative;
`;

const Button = styled.button`
  background-color: #D6ED16;
  margin: 10px auto;
  display: block;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 10px;
  animation:pulse 1s infinite;
  color: black;
  

  @keyframes pulse {
  0% {    transform: scaleX(1)  }
  50% {    transform: scale3d(1.05, 1.05, 1.05)  }
  to {    transform: scaleX(1)  }
}
`;

const fastSpin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(1440deg); } /* 4 full spins */
`;

const slowSpin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } /* 1 full spin */
`;

const WheelImage = styled.img<{ spinning: boolean }>`
  display: block;
  margin: auto;
  width: 100%;

  ${(props) =>
    props.spinning &&
    css`
      animation: ${fastSpin} 2s linear 0s 1 normal forwards, ${slowSpin} 3s ease-out 2s 1 normal forwards;
    `}
`;

const Pin = styled.img`
  width: 65px;
  position: absolute; /* Adjust position as needed */
  top: 50%; /* Adjust position as needed */
  left: 52%; /* Adjust position as needed */
  transform: translate(-50%, -50%); /* Adjust position as needed */
  z-index: 1000;
`;
function App() {
  const [modal, setModal] = useState<boolean>(false);
  const [roullet, setRoullet] = useState<boolean>(true);
  const [spinning, setSpinning] = useState<boolean>(false);

  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = queryParams.get('_token');
  const [token, setToken] = useState<string | null>(tokenFromUrl);

  useEffect(() => {
    if (!token) {
      const newToken = 'uuid_1r7e0753m5ta_1r7e0753m5ta667c8174b80428.31485206';
      setToken(newToken);

      queryParams.set('_token', newToken);
      navigate(`${window.location.pathname}?${queryParams.toString()}`, { replace: true });
    }
  }, [token, navigate, window.location.pathname, window.location.search]);

  const startSpin = () => {
    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
      setRoullet(false);
      setModal(true);
    }, 5000); // Total spin duration
  };

  return (
    <>
      <iframe
        src="https://bet7k.com/"
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          opacity: 1,
          filter: "blur(1.5px)",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1000,
        }}
      ></iframe>
      <div id="modal" style={{ zIndex: 1001 }}>
        {roullet && (
          <>
            <ModalComponent>
              <Headline>
              <span style={{fontSize:'17px'}}>VOCÊ GANHOU 1 GIRO GRÁTIS NA </span>
                <span style={{color:'#D6ED16', fontSize:'25px'}}> <br />ROLETA PREMIADA</span> <br />
                <span style={{fontSize:'13px'}}>GIROU, GANHOU E RESGATOU!</span>
              </Headline>
              <Container>
                <Pin onClick={startSpin}  src={Marker} alt='' />
                <WheelImage src={Wheel} spinning={spinning} />
              </Container>
              <div>
                <Button onClick={startSpin}>
                  GIRE A ROLETA <img src={RoulletIcon} alt="" />
                </Button>
              </div>
            </ModalComponent>
          </>
        )}
        {modal && (
          <ModalComponent>
            <Headline>
              <span style={{color:'#D6ED16', fontSize:'35px'}}>BOAAA!</span> <br />
              Você ganhou até <br /> 100 rodadas grátis!
            </Headline>
            <div className="modal-content">
              <a href={`https://rodadab7k/?_lp=1&_token=${token}`}>
                <div className="container">
                  <img src={espadilhaIcon} alt="Espadilha Icon" />
                  20 rodadas grátis | Deposite R$10
                </div>
              </a>
              <a href={`https://rodadab7k/?_lp=1&_token=${token}`}>
                <div className="container">
                  <img src={espadilhaIcon} alt="Espadilha Icon" />
                  40 rodadas grátis | Deposite R$20
                </div>
              </a>
              <a href={`https://rodadab7k/?_lp=1&_token=${token}`}>
                <div className="container">
                  <img src={espadilhaIcon} alt="Espadilha Icon" />
                  100 rodadas grátis | Deposite R$50
                </div>
              </a>
              <div className="btn">
                <a href={`https://rodadab7k/?_lp=1&_token=${token}`}>DEPOSITAR</a>
                <button onClick={() => window.location.href = `https://rodadab7k/?_lp=1&_token=${token}`}>Fechar</button>
              </div>
            </div>
            <div>
              <img id="logo" src={logo} alt="CassinoPix Logo" />
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
}

export default App;
